define("discourse/plugins/layouts-profile/discourse/widgets/profile", ["exports", "discourse/widgets/widget", "discourse/widgets/component-connector", "virtual-dom", "discourse/widgets/post"], function (_exports, _widget, _componentConnector, _virtualDom, _post) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _widget.createWidget)('profile', {
    tagName: 'div.user-profile.widget-container',
    buildKey: function buildKey() {
      return 'user-profile';
    },
    defaultState: function defaultState(attrs) {
      return {
        topic: attrs.topic,
        bookmarked: attrs.topic ? attrs.topic.bookmarked : null
      };
    },
    canInviteToForum: function canInviteToForum() {
      return Discourse.User.currentProp('can_invite_to_forum');
    },
    toggleBookmark: function toggleBookmark() {
      this.state.bookmarked = !this.state.bookmarked;
      var topicController = this.register.lookup('controller:topic');
      topicController.send('toggleBookmark');
    },
    showLogin: function showLogin() {
      this.sendShowLogin();
    },
    sendShowLogin: function sendShowLogin() {
      var appRoute = this.register.lookup('route:application');
      appRoute.send('showLogin');
    },
    sendShowCreateAccount: function sendShowCreateAccount() {
      var appRoute = this.register.lookup('route:application');
      appRoute.send('showCreateAccount');
    },
    showInvite: function showInvite() {
      var topicRoute = this.register.lookup('route:topic');
      topicRoute.send('showLogin');
    },
    html: function html(attrs, state) {
      var currentUser = this.currentUser;
      var topic = state.topic;
      var contents = [];

      if (currentUser) {
        var username = currentUser.get('username');
        var userPath = currentUser.get('path');
        contents.push((0, _virtualDom.h)('div.avatar', (0, _post.avatarImg)('large', {
          template: currentUser.get('avatar_template'),
          username: username
        })), (0, _virtualDom.h)('div.profile-top', this.attach('user-menu-links', {
          path: userPath
        })));
      } else {
        contents.push((0, _virtualDom.h)('div.widget-title', I18n.t('profile_widget.guest')));

        if (!topic) {
          contents.push(this.attach('login-required'));
        }
      }

      var actions = [];

      if (topic) {
        if (currentUser && topic.details.can_invite_to) {
          actions.push(this.attach('button', {
            label: 'topic.invite_reply.title',
            icon: 'envelope-o',
            action: 'showInvite',
            className: 'btn-small'
          }));
        }

        actions.push(this.attach('button', {
          action: 'share',
          label: 'topic.share.title',
          className: 'btn-small share',
          icon: 'link',
          data: {
            'share-url': topic.get('shareUrl')
          }
        }));

        if (currentUser) {
          var tooltip = state.bookmarked ? 'bookmarks.created' : 'bookmarks.not_bookmarked';
          var label = state.bookmarked ? 'bookmarked.clear_bookmarks' : 'bookmarked.title';
          var buttonClass = 'btn btn-small bookmark';
          if (state.bookmarked) buttonClass += ' bookmarked';
          actions.push(this.attach('button', {
            action: 'toggleBookmark',
            title: tooltip,
            label: label,
            icon: 'bookmark',
            className: buttonClass
          }), new _componentConnector.default(this, 'topic-notifications-button', {
            topic: topic,
            appendReason: false,
            showFullTitle: true,
            className: 'btn-small'
          }));
        } else {
          actions.push(this.attach('button', {
            label: 'topic.reply.title',
            icon: 'reply',
            action: 'sendShowLogin',
            className: 'btn-small'
          }));
        }
      } else {
        if (!this.site.mobileView && this.canInviteToForum()) {
          actions.push(this.attach('link', {
            route: 'userInvited',
            icon: 'user-plus',
            label: 'user.invited.title',
            model: currentUser,
            className: 'btn btn-small'
          }));
        }
      }

      contents.push((0, _virtualDom.h)('div.profile-actions', actions));
      return (0, _virtualDom.h)('div.widget-inner', contents);
    }
  });

  _exports.default = _default;
});

